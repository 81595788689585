<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <b-form class="container">
        <b-row>
          <b-col md="12">
            <b-form-group>
              <label>Cikkszám</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model="formData.article_number"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Név</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model="formData.name"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label>Mennyiség egység</label>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <v-select
                  v-model="formData.unit"
                  label="title"
                  :options="quantityUnits"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Nettó beszerzési egységár</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model="formData.unit_price"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Beszerzési ár dátuma</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model="formData.sale_price_date"
                  type="date"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Profit</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model="formData.profit"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Áfa</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model="formData.vat"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label>Kategória</label>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <v-select
                  v-model="selectedCategory"
                  label="name"
                  :options="allCategories()"
                  :state="errors.length > 0 ? false : null"
                  @input="selectId($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Mentés
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      formData: {
        article_number: null,
        name: null,
        unit: null,
        unit_price: null,
        sale_price_date: null,
        categories: null,
        profit: null,
        vat: 27,
      },
      selectedCategory: null,
      loading: false,
      quantityUnits: ['db', 'kg', 'l'],

      required,
    }
  },
  methods: {
    ...mapActions(['createProduct', 'fetchCategories']),
    ...mapGetters(['allCategories']),
    initCategories() {
      this.loading = true
      this.fetchCategories().then(res => {
        this.loading = false
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.createProduct(this.formData).then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sikeres mentés',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.replace({
              name: 'products-index',
            })
          })
        }
      })
    },
    selectId(e) {
      this.formData.categories = e.id
    },
  },
  created() {
    this.initCategories()
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
